import logo from './../../assets/logo/logo3.jpeg';
export default function Dashboard(props) {
    return (
        <div className="App">
            
        <header className="App-header">
        <p className="header-app">
                Make My Algo trade
            </p>
          <img src={logo} className="App-logo" alt="logo" />
          
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            
          </a>
          ''Trading Unleashed: Elevate Your Strategy with Our Hosting Excellence,,
        </header>
      </div>   
    )
}