const Button = (props) => {

    const onClick=()=>{
        if(props.onClick) {
            props.onClick()
        }
    }
    const buttonStyle = {
        padding: '10px',
        fontFamily: "inherit",
        border: 'none',
        'border-radius': '4px'
    }
    return (
      <button
        style={buttonStyle}
        onClick={onClick()}
        className={`${props.className ? props.className : ""} cursor-pointer  `}
      >
        {props.title}
      </button>
    );
}
export default Button