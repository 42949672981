import ic_dashboard from '../../assets/navigation-icon/Dashboard.svg'
import ic_connector from '../../assets/navigation-icon/Connector.svg'
import ic_indicator from '../../assets/navigation-icon/Indicator.svg'
import ic_options from '../../assets/navigation-icon/Options-pay-off.svg'
import ic_time_base from '../../assets/navigation-icon/Time-based.svg'
import Dashboard from '../dashboard/Dashboard'
import Indicator from '../indicator/Indicator'
import TimeBased from '../time-based/TimeBased'
import OptionsPayOff from '../options-pay-off/OptionsPayOff'
import Connectors from '../connectors/Connectors'
export const PATH = {
    DASHBOARD: "/",
    TIME_BASED: "/time-based",
    INDICATOR: "/indicator",
    OPTION_PAY_OFF: "/option-pay-off",
    CONNECTORS: "/connectors"
}
export const NAVIGATION = [
    {
        id: "DASHBOARD",
        name: "Dashboard",
        icon: ic_dashboard,
        hide: false,
        accessTo: "-1",
        country: ["-1"],
        path:'/',
        onClick: () => {
            // RouteManager.navigateToPage(PAGE_ROUTES.CONTRACTOR_DASHBOARD)
            // if(!isViewportLarge()) {
            //     SideBarService.toggleSideBar()
            // }
        }
    },
    {
        id: "INDICATOR",
        name: "Indicator",
        icon: ic_indicator,
        hide: false,
        accessTo: "-1",
        country: ["-1"],
        path: PATH.INDICATOR,
        onClick: () => {
            // RouteManager.navigateToPage(PAGE_ROUTES.CONTRACTOR_DASHBOARD)
            // if(!isViewportLarge()) {
            //     SideBarService.toggleSideBar()
            // }
        }
    },
    {
        id: "TIME_BASED",
        name: "Time-Based",
        icon: ic_time_base,
        hide: false,
        accessTo: "-1",
        country: ["-1"],
        path: PATH.TIME_BASED,
        onClick: () => {
            // RouteManager.navigateToPage(PAGE_ROUTES.CONTRACTOR_DASHBOARD)
            // if(!isViewportLarge()) {
            //     SideBarService.toggleSideBar()
            // }
        }
    },
    {
        id: "OPTION_PAY_OFF",
        name: "Options Pay-off",
        icon: ic_options,
        hide: false,
        accessTo: "-1",
        country: ["-1"],
        path: PATH.OPTION_PAY_OFF,
        onClick: () => {
            // RouteManager.navigateToPage(PAGE_ROUTES.CONTRACTOR_DASHBOARD)
            // if(!isViewportLarge()) {
            //     SideBarService.toggleSideBar()
            // }
        }
    },
    {
        id: "CONNECTORS",
        name: "Connectors",
        icon: ic_connector,
        hide: false,
        accessTo: "-1",
        country: ["-1"],
        path: PATH.CONNECTORS,
        onClick: () => {
            // RouteManager.navigateToPage(PAGE_ROUTES.CONTRACTOR_DASHBOARD)
            // if(!isViewportLarge()) {
            //     SideBarService.toggleSideBar()
            // }
        }
    }
];

export const RoutePathElement = [
    {
        id: 'DASHBOARD',
        path: '/',
        element: (props) =>{
            return <Dashboard {...props}/>
        }
    },
    {
        id: 'DASHBOARD',
        path: '**',
        element: (props) =>{
            return <Dashboard {...props}/>
        }
    },
    {
        id: 'DASHBOARD',
        path: '',
        element: (props) =>{
            return <Dashboard {...props}/>
        }
    },
    {
        id: 'INDICATOR',
        path: '/indicator',
        element: (props) =>{
            return <Indicator {...props}/>
        }
    },
    {
        id: 'TIME_BASED',
        path: PATH.TIME_BASED,
        element: (props) =>{
            return <TimeBased {...props}/>
        }
    },
    {
        id: 'OPTION_PAY_OFF',
        path: PATH.OPTION_PAY_OFF,
        element: (props) =>{
            return <OptionsPayOff {...props}/>
        }
    },
    {
        id: 'CONNECTORS',
        path: PATH.CONNECTORS,
        element: (props) =>{
            return <Connectors {...props}/>
        }
    }
]