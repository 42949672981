import { Fragment, useState } from "react"
import { NAVIGATION } from "../router/Router"
import { Link } from "react-router-dom"

const LeftMenu = () => {
    const [activeNavigation, setActiveNavigation] = useState(0)
    const getFilterNavigation = () => {
        let filteredNavigation = NAVIGATION.filter(nav=>!nav.hide)
        return filteredNavigation
    }
    const renderNavigation = () => {
        const navigation = getFilterNavigation()
        
        return navigation?.map((nav,index)=>{
            return (
            <Link to={nav.path} className={`p-6 py-3 d-flex cursor-pointer  ${index === activeNavigation? "active-item":"nav-item"}`}
            onClick={()=>{
                setActiveNavigation(index)
            }}
            >
                <img className="pr-2" width="20" src={nav.icon} alt=""/>
                {nav.name}
            </Link>
            )
        })
    }
    
    return <Fragment>
        <div className="left-menu bg-ghostwhite   ">
            {renderNavigation()}
        </div>
    </Fragment>
}
export default LeftMenu