import { Fragment } from "react"
import Button from "../common/Button"
import logo from './../../assets/logo/logo3.jpeg';
const TopMenu = () => {
    return <Fragment>
        <div className="top-menu bg-ghostwhite p-2 d-flex align-item-center">
            <div className="d-flex align-item-center">
                <img style={{width:40}} src={logo} alt="" />
                Make My Algo Trade
            </div>
            <div className="d-flex">
                <Button title="Create Indicator-Based Strategy" className="pr-2 bg-app text-white mr-2"/>
                <Button title="Logout" className="bg-gray"/>
                
            </div>
        </div>
    </Fragment>
}
export default TopMenu