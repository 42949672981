import { Fragment } from "react";
import "./App.css";

import TopMenu from "./component/Layout/TopMenu";
import LeftMenu from "./component/Layout/LeftMenu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RoutePathElement } from "./component/router/Router";


function App() {
  return (
    <Fragment>
      <BrowserRouter>
      <TopMenu />
      <div className="d-flex section-panel">
        <div className="left-nav scroll-y">
          <LeftMenu />
        </div>
        <div className="main-panel scroll-y">
          
          <Routes>
            {
              RoutePathElement.map(element=>{
                return <Route path={element.path} element={element.element()}/>
              })
            }
            
          </Routes>
          
        </div>
      </div>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
